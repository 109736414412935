.header {
  @apply bg-slate-100;
}

:global(.dark) .header {
  @apply bg-gray-900;
}

.innerHeader {
  @apply flex flex-row justify-items-start max-w-screen-md mx-auto py-4 px-4;
}

.nav {
  @apply flex flex-col md:flex-row flex-wrap md:items-center w-full space-y-6 md:space-y-0 mr-8;
}

.logoLink {
  @apply font-semibold text-xl;
}

.headerLogo {
  @apply flex items-center;
}

.headerLogoCursor {
  @apply ml-2 h-5 w-2.5 leading-6 bg-purple-400 inline-block;
  animation: cursor-blink 1.2s infinite;
  -webkit-animation: cursor-blink 1.2s infinite;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@-webkit-keyframes cursor-blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.headerLinks {
  @apply flex md:ml-auto font-normal space-x-8;
}

.navItem {
  @apply text-gray-700 cursor-pointer flex items-center justify-center;
}

:global(.dark) .navItem {
  @apply text-white;
}

.navItemActive {
  @apply font-normal underline underline-offset-8 decoration-purple-500;
}

:global(.dark) .navItemActive {
  @apply decoration-purple-400;
}
