@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

@layer base {
  body {
    padding: 0;
    margin: 0;
    @apply dark:bg-gray-800 dark:text-gray-200;
  }

  ::selection {
    @apply text-white bg-indigo-500;
  }
}

p {
  @apply text-lg my-5;
}

li {
  @apply my-2;
}

h1 {
  @apply text-4xl my-10 font-bold;
}

h2 {
  @apply text-2xl mt-14 mb-6 font-semibold;
}

h3 {
  @apply text-xl mt-14 mb-5 font-semibold;
}

.link {
  @apply dark:text-white underline underline-offset-4 hover:decoration-purple-500 dark:hover:decoration-purple-400;
}
